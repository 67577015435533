.class-room-container {
    height: 100vh;
    background-color: white;

}

.class-room {
    max-height: 100%;
    height: 800px;
    background-color: white;
}

.class-room .class-end-icon {
    background-color: white;
    font-size: 30px;
    color: white;
    margin: 0 10px !important;
    border-radius: 25px;
    cursor: pointer;
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.video-icon {
    font-size: 15px;
    color: white;
    background-color: #434943;
    margin: 0 10px !important;
    border-radius: 100%;
    cursor: pointer;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.4s;
}

.icon-red {
    background-color: #ff0000bc !important;
}

.instruction .video-icon {
    font-size: 15px;
    color: white;
    background-color: #434943;
    margin: 0 10px !important;
    border-radius: 100%;
    cursor: pointer;
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.fa-phone-square-alt:hover,
.video-icon:hover {
    transform: scale(1.1);
}

.pointer-event-none {
    pointer-events: none;
}


/* //chat room  */
.class-room .chat-room {
    background-color: #F1F3F7;
    border-radius: 10px;
}

.chat-header {
    background-color: #E7EAEF !important;
    border-radius: 10px;
    padding: 20px 10px !important;
}

#remote-video,
#remote-container {
    background-color: #E7EAEF;
}

#user-video {
    height: 200px;
    width: 200px;
    overflow: hidden;
    cursor: pointer;
    /* border: 2px solid white; */
    border-radius: 10px !important;
    right: 10px;
    top: 60px;
    transition: 0.5s;
}


#user-video video {
    border-radius: 10px !important;
    overflow: hidden;
    max-width: 100% !important;
    position: initial !important;
}

.expand-share {
    display: none;
}

.remote-user:hover>.expand-share {
    display: flex;
}

#remote-container .remote video {
    border-radius: 10px !important;
    overflow: hidden;
    max-width: 100% !important;
    position: initial !important;
}

.video-room {
    user-select: none;
    white-space: pre-wrap;
}

.timer {
    background: rgb(27, 34, 27, 0.2);
    border-radius: 15px;
}

.recordin-Icon {
    background-color: #fa0707;
    height: 14px;
    width: 14px;
    border: 3px solid white !important;
    border-radius: 100%;
}

.recording-animation {
    animation: recording 1s infinite;
}

.class-room .call-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    background-color: #E7EAEF;
    border-radius: 0 0 10px 10px;
}

.class-room .video-controller {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

@keyframes recording {
    0% {
        transform: scale(0.9);
    }

    100% {
        transform: scale(1.5);
    }
}



/* css modification  */
.remote {
    width: 200px;
    height: 200px;
    border-radius: 10px;
    /* border: 2px solid white; */
    overflow: hidden;
    transition: 0.5s;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

#remote-container {
    display: flex;
    height: calc(100% - 58px) !important;
    position: relative;
    align-items: center;
    justify-content: center;
    border-radius: 10px 10px 0 0;
    /* flex-direction: column; */
}

/* Overide agora video player */
.agora_video_player {
    height: auto !important;
    max-height: 100% !important;
}

#remote-container .player_parent {
    background-color: transparent !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    width: 100%;
    height: 100%;
}


@media (max-width:650px) {
    .class-room-container {
        justify-content: flex-star !important;
        align-items: flex-start !important;
    }

    .class-room {
        height: 95vh;
    }

    #user-video {
        width: 140px;
        height: 140px;
        position: absolute !important;
        left: 10px !important;
        top: 20px !important;
        pointer-events: none !important;
    }

    .remote {
        width: 100% !important;
    }

    #remote-container {
        flex-direction: column !important;
    }

    #remote-container>section {
        width: 100% !important;
    }

    .class-room-container {
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

/* video access  */
.video-access-container {
    height: 100vh;
}