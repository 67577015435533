.sc-iwjdpV.gDwoDB {
    padding: 0 13px !important;
}
.bxjLuM{
    background-color: red !important;
    padding: 0 13px !important;
}
.sc-fotOHu.krDDfs {
    background-color: red !important;
    padding: 0 13px !important;
}

.reactour__helper--is-open {
    padding: 24px 15px !important;
    border-radius: 10px !important;

}

nav .reactour__dot {
    margin: 5px 10px !important;
    color: black !important;
}

/* Bottom */
.bzwrgi {
    margin-top: 20px !important;
}
.dWQODV button:not(.btn){
    display: none;

}

.react-tour-button{
    position: fixed;
    left: 0;
    padding: 10px 5px !important;
    color: white;
    background-color: var(--color-sky);
    border-radius: 2px 15px 15px 2px;
    z-index: 5;
    bottom: 10px;
    width: 32px;
    overflow: hidden;
    transition: 0.4s;
}
.react-tour-button:hover{
    width: 110px; 
}


