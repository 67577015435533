.btn-msg {
  background-color: #089D121A;
  color: #089D12;
  font-size: 14px;
  font-family: "MuseoSansRounded900", regular;
  border-radius: 5px;
}

.btn-library {
  background-color: #089D12;
  color: white;
  border-radius: 5px;
  font-family: "MuseoSansRounded900", regular;
  font-size: 14px;
}

.user-chat-box {
  background-color: #FFFFFF;
  color: #434943;
  font-family: "MuseoSansRounded500", regular;
  font-size: 14px;
  padding: 10px !important;
  border-radius: 5px;
  margin: 7px 10px !important;
  max-width: 70%;
  float: right;
}

.user-bottom-arrow:after {
  content: '';
  position: absolute;
  right: 20px;
  bottom: 0;
  margin: 0 auto;
  width: 0;
  height: 0;
  border-top: 10px solid white;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
}

.remote-chat-box {
  background-color: #089D12;
  color: white;
  font-family: "MuseoSansRounded500", regular;
  font-size: 14px;
  padding: 10px !important;
  border-radius: 5px;
  margin: 7px 10px 1px 10px !important;
  max-width: 70%;
  float: left;
  overflow: hidden;
  word-break: break-all;
}

.remote-bottom-arrow:after {
  content: '';
  position: absolute;
  left: 20px;
  bottom: 0;
  margin: 0 auto;
  width: 0;
  height: 0;
  border-top: 10px solid #089D12;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
}

.chat-room-footer {
  background-color: white;
  border-radius: 10px;
  box-shadow: 2px 2px 3px 3px rgba(192, 190, 190, 0.26);
}

.msg-input-box {
  background-color: white;
  width: 100%;
  border-radius: 10px;
  padding: 10px 5px !important;
  outline: none;
  border: none;
}

.fa-paper-plane {
  background-color: #089D12;
  color: white;
  border-radius: 10px;
  height: 100%;
}

.chat-room-footer ::placeholder {
  font-family: "MuseoSansRounded500", regular;
  font-size: 16px;
  color: #8C92A1;
}

.chat-room-body {
  overflow: auto;
  flex-grow: 1;
}

.call-chat-box {
  width: 95%;
  max-width: 500px;
  min-width: 350px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  position: absolute;
}

.video-chat-body {
  background-color: gainsboro;
}

/* chat  */
.user-chat-box-container {
  margin: 7px 10px !important;
  max-width: 70%;
  float: right;
}

.user-chat-box-body {
  background-color: #FFFFFF;
  color: #434943;
  font-family: "MuseoSansRounded500", regular;
  font-size: 14px;
  padding: 10px !important;
  border-radius: 5px;
  word-break: break-all;
}

.chat-time {
  margin-top: 4px;
  font-family: "MuseoSansRounded700", regular;
  font-size: 12px;
}

.chat-day-box {
  display: flex;
  justify-content: center;

}

.chat-day {
  padding: 5px 10px !important;
  border-radius: 5px;
  background-color: #FFCA00;
  font-family: "MuseoSansRounded700", regular;
  font-size: 12px;
  color: black;
  display: inline-block;
}


#chat-body,
.hide-scroll,
body,
textarea {
  --scrollbarBG: #F1F3F7;
  --thumbBG: #90A4AE;
}

#chat-body::-webkit-scrollbar,
.hide-scroll::-webkit-scrollbar,
body::-webkit-scrollbar,
textarea::-webkit-scrollbar {
  width: 10px;
  cursor: pointer !important;
}

#chat-body,
.hide-scroll,
body,
textarea {
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}

#chat-body::-webkit-scrollbar-track,
.hide-scroll::-webkit-scrollbar-track,
body::-webkit-scrollbar-track,
textarea::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
  cursor: pointer !important;
}

#chat-body::-webkit-scrollbar-thumb,
.hide-scroll::-webkit-scrollbar-thumb,
body::-webkit-scrollbar-thumb,
textarea::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  border-radius: 6px;
  border: 2px solid var(--scrollbarBG);
  cursor: pointer !important;
}

#lib-body {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
}

#lib-body::-webkit-scrollbar {
  display: none;
}

.chat-footer-container {
  background-color: #F1F3F7;
  border-radius: 0 0 10px 10px;
}

.chat-send {
  background: var(--unnamed-color-089d12) 0% 0% no-repeat padding-box;
  background: #089D12 0% 0% no-repeat padding-box;
  border-radius: 10px;
  width: 50px;
  height: 50px;
  border: none;
  outline: none;
  box-shadow: none !important;
}

.chat-box .chat-footer {
  height: 60px;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 20px #00000014;
  border-radius: 20px;
}

.chat-box .chat-footer .chat-input-box {
  padding: 5px !important;
  border: none;
  outline: none;
  background-color: white;
  border-radius: 10px;
}

@media (max-width:700px) {
  .table-break.w-50 {
    display: block;
    width: 100% !important
  }
}