.s3-fileupload .select-file {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.s3-fileupload .select-file .gallery,
.s3-fileupload .select-file .web-cam {
    border: 2px dotted var(--color-green);
    display: flex;
    border-radius: 10px;
    align-items: center;
    justify-self: center;
    width: 150px;
    height: 150px;
    max-width: 100%;
    max-height: 100%;
    margin: 10px !important;
    padding: 20px !important;
    text-align: center;
    font-family: "Fredoka One", regular;
    cursor: pointer;
}