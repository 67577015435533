.userdash {
    height: 100vh;
}

.userdash .right-dash {
    height: 100vh;
}


.left-icon-sm-strip {
    background-color: var(--color-sky);
    display: flex;
    height: 100vh;
}

.left-icon-sm-strip i {
    color: white;
}

.left-dash .overlay-text {
    display: none;
    background-color: #ffcb00;
    z-index: 2;
    color: white;
    border-radius: 5px;
    font-family: "MuseoSansRounded500", regular;
    font-size: 14px;
    transition: 0.3s;
    left: 40px;
}

.overlay-cover:hover>.overlay-text {
    display: initial;

}

.userdash .left-dash ul li {
    margin: 10px 0px !important;
    padding: 5px 0px !important;
    padding-left: 10px !important;
    color: rgb(190, 187, 187);
    list-style: none;
    border-radius: 10px;
    cursor: pointer;
}

.userdash .left-icon-lg-strip ul li p {
    color: #1b221b;
    opacity: 0.5;
    letter-spacing: 0.96px
}

.icon-width {
    width: 40px;
    display: inline-block;
}

.userdash .left-dash .active-tab p {
    color: white;
    opacity: 1;
}

.userdash .left-dash .active-tab {
    color: white;
    background-color: #099d14 !important;
}

.active-icon-color path {
    fill: #fff !important;
}

.inactive-icon-color path {
    fill: #888c8b !important;
}

.active-icon-color {
    fill: #fff !important;
}

.inactive-icon-color {
    fill: #888c8b !important;
}

.userdash .left-dash .active-tab-sm {
    color: white;
    background-color: #ffcb00 !important;
}

.icon-box {
    height: 40px;
    width: 40px;
}

.userdash .icon-border {
    border: 2px solid #EAEAEA;
    border-radius: 5px;
}

.userdash .icon-border:hover {
    border: 2px solid var(--color-sky);
}

.userdash {
    height: 100vh;
}

.userdash .left-dash {
    /* height: 100vh; */
    overflow: auto;
}

.userdash .right-dash {
    height: 100vh;
    overflow: auto;
}

/*  Menu  */
.userdash .menu ul li {
    margin: 10px 0px !important;
    padding: 5px 0px !important;
    padding-left: 10px !important;
    color: black;
    list-style: none;
    border-radius: 10px;
    cursor: pointer;
}

.userdash .menu .menu-top {
    height: 40px;
    width: 60%;
    margin: 0 auto !important;
    background-color: #099d14;
    border-radius: 0 0 250px 250px;
}

.userdash .menu .active-tab {
    color: white;
    background-color: #099d14 !important;
}

/* tutor home  */
.user-home .banner-box {
    /* height: 382px; */
    width: 100%;
    background-color: #ffcb00;
    border-radius: 20px;
}

.user-home .banner-bottom {
    height: 10px;
    width: 90%;
    border-radius: 0 0 25px 25px;
    background-color: #e0b402;
}

.user-home .analytic-data {
    border-radius: 10px;
    border: 2px solid rgb(240, 237, 237);
}

.alert-class {
    background-color: #ff5757;
    padding: 10px 20px !important;
    border-radius: 10px;
}

.btn-new-notify {
    background-color: #ffcb00;
    border-radius: 30px;
    height: 39px;
}

.btn-detail {
    background-color: var(--color-sky);
    border-radius: 10px;
    color: white;
    height: 39px;
    box-shadow: none !important;
}

.btn-read {
    background-color: #d3d9db;
    border-radius: 30px;
    height: 39px;
}

.btn-unread {
    color: #0fa5db !important;
    background-color: #cce7f1;
    border-radius: 30px;
    height: 39px;
}

.border-orange {
    border: 1px solid #ffcb00;
    border-radius: 10px;
}

.userdash .notify-card:hover {
    border: 1px solid var(--color-sky);
}

.active-tutor-card {
    background-color: #FFCA0033;
    border: 2px solid #FFCA00 !important;
}

.offline-tag {
    background-color: red;
    height: 14px;
    width: 14px;
    border: 2px solid white;
    border-radius: 100%;
}

.online-tag {
    background-color: green;
    height: 14px;
    width: 14px;
    border: 2px solid white;
    border-radius: 100%;
}

.new-notification-tag {
    background-color: #ffcb00;
    height: 14px;
    width: 14px;
    border: 2px solid white;
    border-radius: 100%;
}

.tutor-card {
    box-shadow: 0 0 9px 3px rgb(231, 228, 228);
    transition: 0.7s;
}

.tutor-big-card {
    box-shadow: 0 0 9px 3px rgb(231, 228, 228);
    transition: 0.7s;
}

.tutor-card:hover {
    box-shadow: 0 0 9px 3px rgb(255, 241, 241);
    transform: scale(0.98);
}


/* My Profile  */
.userdash .my-address {
    background-color: #f4f6fb;
}

.userdash .border-dot {
    border: 1px dotted rgb(175, 174, 174);
}

/* calendar  */
.cl-body {
    background-color: #f4f6fb !important;
    border: none !important;
    padding: 10px 0 !important;
}

.tilename {
    padding: 10px !important;
    background-color: #f4f6fb !important;
    background-color: red;
}

.reshedule-btn {
    background-color: rgb(241, 226, 229);
    color: rgb(240, 44, 83);
    border-radius: 4px;
}

.returnCall-btn {
    background-color: #ccccff;
    color: rgb(240, 44, 83);
    border-radius: 4px;
}

/* library  */
.tutor-library .input-box {
    box-shadow: 0px 5px 20px #00000014;
    border: none;
    outline: none;
    border-radius: 10px;
    height: 49px;
    border: 1px solid #EAEAEA !important;

}

.search-box {
    box-shadow: 0px 5px 20px #00000014;
    border: none;
    outline: none;
    border-radius: 10px;
    height: 49px;
    border: 1px solid #EAEAEA !important;

}

.course-img img {
    transition: 0.5s;
}

.course-img img:hover {
    transform: scale(1.1);
}

.tutor-library .course-card:hover {
    border: 1px solid green;
}

.check-out {
    width: 24px;
    height: 24px;
    background: var(--unnamed-color-f9f9f9) 0% 0% no-repeat padding-box;
    background: #F9F9F9 0% 0% no-repeat padding-box;
    border: 2px solid #159D18;
    border-radius: 5px;
    opacity: 1;
}

.check-incomplete-out {
    width: 24px;
    height: 24px;
    background: var(--unnamed-color-f9f9f9) 0% 0% no-repeat padding-box;
    background: #F9F9F9 0% 0% no-repeat padding-box;
    border: 2px solid #EAEAEA;
    border-radius: 5px;
    opacity: 1;
}

.check-tik {
    color: #159D18;
    width: 13px;
    height: 10px;
}

/* My student  */
.userdash .chat-student-card:hover {
    border: 1px solid var(--color-sky);
    cursor: pointer;
}

.btn-chat {
    border: 2px solid var(--color-sky);
    ;
    border-radius: 10px;
    background-color: white;
}

.btn-chat:hover {
    background-color: var(--color-sky);
    ;
    border-radius: 10px;
    color: white;
}

.btn-report {
    border-radius: 13px;
    background-color: crimson;
    box-shadow: 0 4px 2px rgb(190, 52, 80);
    color: white;
    font-family: "Roboto", sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    padding: 6px 10px !important;
}

.btn-favourite {
    background-color: #FC595B1A;
    color: #ff5757d0;
    font-size: 14px;
    border-radius: 15px;
    padding: 2px 7px !important;
}

.btn-unfavourite {
    border: 2px solid #C3C3C3;
    color: #434943;
    font-size: 14px;
    border-radius: 15px;
    padding: 2px 7px !important;
}

/* My chat  */
.chat-box .chat-header {
    background-color: rgb(233, 227, 227);
    border-radius: 10px 10px 0 0;
}

.chat-box .chat-body {
    background-color: #F1F3F7;
    height: calc(100vh - 240px);
}

.chat-user-list-box {
    height: calc(100vh - 100px);
    overflow: auto;
}


.user-chat .box {
    box-shadow: 2px 2px 5px 5px whitesmoke;

}

.user-chat .box>i {
    font-size: 18px;
    color: #777;


}

.user-chat .box>input {

    height: 40px;
    border: none;
    outline: none;
    font-size: 18px;
    padding-left: 10px;

}

/* Support  */
.user-support .banner-box-support {
    /* height: 270px; */
    width: 100%;
    background-color: var(--color-sky);
    border-radius: 20px;
}

.user-support .banner-bottom-support {
    height: 10px;
    width: 90%;
    border-radius: 0 0 25px 25px;
    background-color: #169fd1;
}

.btn-support {
    position: fixed;
    right: 30px;
    bottom: 20px;
    z-index: 2;
    background-color: #ffcb00;
    /* color:white; */
    border-radius: 20px;
    box-shadow: none !important;
    transition: 0.6s;
}

.btn-ar-support {
    position: fixed;
    left: 30px;
    bottom: 20px;
    z-index: 2;
    background-color: #ffcb00;
    border-radius: 20px;
    transition: 0.6s;
    box-shadow: 0px 0px 10px 2px #dcac4b !important;
}

.btn-support:active {
    transform: scale(0.9);
}

.hover-shadow {
    transition: 0.6s;
    padding: 8px !important;
}

.hover-shadow:hover {
    transition: 0.6s;
    border-radius: 100%;
    background-color: rgb(238, 225, 225);
}

/* Faqs  */
.userdash .back-btn {
    color: white !important;
    background-color: #089D12 !important;
    font-size: 14px !important;
    font-family: "MuseoSansRounded900", regular !important;
    border-radius: 10px;
    letter-spacing: 0.84px;
    text-transform: uppercase;
    height: 39px;
    width: 93px;

}

/* switch  */
.switch {
    position: relative;
    display: inline-block;
    width: 35px;
    height: 20px;
}

.userdash .switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.userdash .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.userdash .slider:before {
    position: absolute;
    content: "";
    height: 15px;
    width: 15px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

.userdash input:checked+.slider {
    background-color: #099d14;
}

.userdash input:focus+.slider {
    box-shadow: 0 0 1px #099d14;
}

.userdash input:checked+.slider:before {
    -webkit-transform: translateX(15px);
    -ms-transform: translateX(15px);
    transform: translateX(15px);
}

/* Rounded sliders */
.userdash .slider.round {
    border-radius: 10px;
}

.userdash .slider.round:before {
    border-radius: 50%;
}


/* search tutor inside Course Module  */
.new-flag {
    background-color: #ffcb00;
    border-radius: 0 10px 0 10px !important;
    color: white;
    position: absolute;
    font-size: 14px;
    top: 0;
    right: 0;
}

.new-ar-flag {
    background-color: #ffcb00;
    border-radius: 0 10px 0 10px !important;
    color: white;
    position: absolute;
    top: 0;
    font-size: 14px;
    left: 0;
}

/* book class  */
.active-time {
    border: 2px solid #ffcb00;
    transition: 0.4s;
    background-color: #ffcb00 !important;
    color: white !important;
}

.available-time:hover {
    background-color: #ffcb00;
    transition: 0.4s;
    color: white !important;
}

.available-time {
    color: #089D12;
    background-color: #089D121A;
}

.unavailable-time {
    background-color: #EAEAEA;
    color: #909090;

}

.booked-time {
    background-color: #FC595B1A;
    color: #FF5757;
}

.select-time {
    background-color: #dae6d4;
    color: #41b30c;
}

.btn-disabled {
    border-radius: 13px;
    background-color: #c8d3d6;
    box-shadow: 0 4px 2px #c8d3d6;
    font-family: "Fredoka One", cursive;
    color: white;
    text-transform: uppercase;
    padding: 6px 10px !important;
}

.join-class-btn {
    border-radius: 15px;
    background-color: #ffcb00;
    transition: 0.5s;
}

.join-class-btn:hover {
    transform: scale(0.95);
}

.cancel-class-btn {
    border-radius: 15px;
    background-color: #7f8283c4;
    transition: 0.5s;
}

.cancel-class-btn:hover {
    transform: scale(0.95);
}

.btn-sky {
    background-color: var(--color-sky);
    color: white;
    text-transform: uppercase;
}


/* My profile  */
.student-profile .plan-summary {
    background-color: #ffcb00;
    border-radius: 10px;

}

.cancel-subscription-hd {
    border: none;
    background-color: #FF5757;
    box-shadow: 0 4px 2px #D94B4B;
    border-radius: 15px;
    color: white;
    height: 39px;
}

.cancel-subscription {
    border: none;
    background-color: #FF5757;
    box-shadow: 0 4px 2px #D94B4B;
    border-radius: 15px;
    color: white;
    height: 49px;
}

.change-password {
    border-radius: 15px;
    background-color: var(--color-sky);
    box-shadow: 0 4px 2px var(--color-sky) !important;
    color: white;
    font-family: "Fredoka One", cursive;
    text-transform: uppercase;
    padding: 6px 10px !important;
    font-size: 14px;
    height: 39px;
    letter-spacing: 0.84px;
}

.load-more {
    background-color: var(--color-yellow);
    border-radius: 5px;
    color: white;
}

.btn-subscription {
    background-color: #FF5757;
    color: white;
    border-radius: 5px;
    padding: 5px 10px !important;
}

.promo-code {
    box-shadow: 0px 5px 20px #00000014;
    border-radius: 10px;
    height: 45px;
    border: 2px solid #EAEAEA !important;
}

.promo-code input {
    border: none;
    outline: none;
    border-radius: 10px;
}

.btn-changePassword {
    background-color: var(--color-sky);
    color: white;
    border-radius: 5px;
    padding: 5px 10px !important;
    height: 39px;
}

.btn-view-course {
    background-color: #FF5757;
    color: white;
    border-radius: 5px;
    padding: 5px 10px !important;
}

/* switch complete  */


/* left minutes  */
.left-minute-popup {
    left: 0;
    right: 0;
    z-index: 5;
    position: absolute;
    background-color: var(--color-sky);
    width: 300px;
    border-radius: 5px;
    border: 1px solid var(--color-sky);
}

.free-classes {
    background-color: var(--color-sky);
}

.left-minute-card {
    background-color: white;
    border-radius: 5px;
}

.border-yellow {
    border: 2px solid var(--color-sky);
    border-radius: 5px;
}

.left-minutes-btn {
    background-color: white;
    border: none;
    border: 2px solid #EAEAEA;
    height: 39px;


}

.left-minutes-btn:hover {
    border: 2px solid var(--color-sky);
    box-shadow: none !important;
}

.upcoming-class-popup {
    right: 0;
    z-index: 5;
    position: absolute;
    width: 300px;
}

.upcoming-class-card {
    background-color: var(--color-sky);
    border-radius: 5px;

}

.schedule-btn:hover {
    background-color: #ffcb00;
    color: white;
}


.apexcharts-toolbar {
    z-index: 0 !important;
}

/* my progres   */
.my-progress-body {
    border: 1px solid rgb(219, 214, 214);
    border-radius: 5px;
    box-shadow: 0px 5px 20px #00000014;
}

.progress-table {
    overflow-x: auto;
}

.btn-cert {
    box-shadow: none !important;
    background-color: var(--color-sky);
    color: white;
    letter-spacing: 0.84px;
    font-family: "Fredoka One", regular;
}

.btn-cert-out {
    box-shadow: none !important;
    background-color: white !important;
    color: var(--color-sky) !important;
    border: 2px solid var(--color-sky) !important;
    letter-spacing: 0.84px;
    font-family: "Fredoka One", regular;
}

.my-progress-body table tr td {
    padding: 10px 5px;
    border: 1px solid rgb(204, 203, 203) !important;
}

.my-progress-body table td {
    text-align: center;
}

.my-progress-body table {
    width: 100%;
    overflow-x: auto;
}

/* mobile navigation  */
.mobile-nav {
    padding: 10px 0 !important;
    min-width: 150px;
    min-height: 130px;
}

.mobile-nav ul li {
    list-style: none;
    cursor: pointer;
    padding: 5px 10px !important;
}

/* webSideMenu */
table .left-icon-sm-strip {
    overflow: hidden;
    width: 0px;
    transition: 0.5s;
}

table .left-icon-sm-strip>div {
    overflow: hidden;
    width: 0px;
    transition: 0.5s;
}

table .left-dash.left-icon-lg-strip {
    transition: 0.5s;
    overflow: auto;
}

table .left-dash.left-icon-lg-strip>div {
    transition: 0.5s;
    overflow: auto;
}

table .left-dash.left-icon-lg-strip>div {
    height: 100vh;
    width: 260px;
}

#web-sideMenu:checked~table .left-dash.left-icon-lg-strip {
    width: 0px !important;
    overflow: hidden;
}

#web-sideMenu:checked~table .left-dash.left-icon-lg-strip>div {
    width: 0px !important;
    overflow: hidden;
    padding: 0 !important;
}

#web-sideMenu:checked~table .left-icon-sm-strip {
    width: 50px;
    overflow: hidden;
}

#web-sideMenu:checked~table .left-icon-sm-strip>div {
    width: 50px;
    overflow: hidden;
}

@media (max-width:600px) {
    .userdash .left-dash {
        height: 100%;
    }

    .userdash {
        height: 100%;
    }

    .user-home .banner-box {
        height: auto;
    }

    .tutor-support .banner-box-support {
        height: auto;
    }

    .how-to-videos video {
        max-width: 350px !important;
        display: block;
        margin: auto;
    }

    .progress-table {
        overflow-x: auto;
        width: 100vw;
    }

    .upcoming-class-popup {
        position: fixed !important;
        top: '60px';
        left: 5vw;
        width: 90vw;
    }

    table .left-dash.left-icon-lg-strip {
        display: none;
    }

    .tutor-card {
        max-width: 390px;
        margin: 10px auto !important;
    }
}