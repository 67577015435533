.tag-box{
    background-color: rgba(204, 196, 196, 0.267);
    border-radius: 3px;
}
.touch-hover:hover{
transform: scale(1.1);
    transition: 0.5s;
}
.toast{
    padding: 10px 10px !important;
}

.bar{
    border-left: 3px solid rgb(12, 171, 219);   
}
.react-calendar__month-view__days__day--weekend {
    color: initial;
  }
  .react-calendar__tile:disabled {
    color: rgb(177, 174, 174) !important;
  }
  .react-calendar__month-view__weekdays {
  color: green;
  font-weight: 900;
  }
  /* //day  */
/* 
  .DayPicker-Month {
      border: 2px solid red;
    display: table;
    margin: 20px !important;
    margin-top: 1em;
    border-spacing: 2;
    width: 100%;
  
  }
  .DayPicker-Months {
    border: 2px solid red;
    width: 100% !important;
  }
  .DayPicker {
    width: 100% !important;
    font-size: 16px !important;
  }
  .DayPicker-NavButton--prev {
    margin-right: 20px;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAVVJREFUWAnN2G0KgjAYwPHpGfRkaZeqvgQaK+hY3SUHrk1YzNLay/OiEFp92I+/Mp2F2Mh2lLISWnflFjzH263RQjzMZ19wgs73ez0o1WmtW+dgA01VxrE3p6l2GLsnBy1VYQOtVSEH/atCCgqpQgKKqYIOiq2CBkqtggLKqQIKgqgCBjpJ2Y5CdJ+zrT9A7HHSTA1dxUdHgzCqJIEwq0SDsKsEg6iqBIEoq/wEcVRZBXFV+QJxV5mBtlDFB5VjYTaGZ2sf4R9PM7U9ZU+lLuaetPP/5Die3ToO1+u+MKtHs06qODB2zBnI/jBd4MPQm1VkY79Tb18gB+C62FdBFsZR6yeIo1YQiLJWMIiqVjQIu1YSCLNWFgijVjYIuhYYCKoWKAiiFgoopxYaKLUWOii2FgkophYp6F3r42W5A9s9OcgNvva8xQaysKXlFytoqdYmQH6tF3toSUo0INq9AAAAAElFTkSuQmCC');
  }
  .DayPicker-Day--today {
    color: #e04d5e;
    font-weight: 700;
  }
  .DayPicker--interactionDisabled .DayPicker-Day {
    cursor: default;
    padding: 20px !important;
  }
  .DayPicker-Day {
    cursor: default;
    margin: 20px !important;
  } */