.tutor-dash {
    height: 100vh;
}

.tutor-dash .right-dash {
    height: 100vh;
}

.tutor-dash .left-dash ul li {
    margin: 10px 0px !important;
    padding: 5px 0px !important;
    padding-left: 10px !important;
    color: rgb(190, 187, 187);
    list-style: none;
    border-radius: 10px;
    cursor: pointer;
}


.tutor-dash .left-dash .active-tab {
    color: white;
    background-color: #099d14 !important;
}

.tutor-dash .icon-border {
    border: 2px solid rgb(240, 236, 236);
    border-radius: 5px;
}

.tutor-dash .icon-border:hover {
    border: 2px solid #20bef8;
    ;
}

.tutor-dash {
    height: 100vh;
}

.tutor-dash .left-dash {
    height: 100vh;
    overflow: auto;
}

.tutor-dash .right-dash {
    height: 100vh;
    overflow: auto;
}

/*  Menu  */
.tutor-dash .menu ul li {
    margin: 10px 0px !important;
    padding: 5px 0px !important;
    padding-left: 10px !important;
    color: black;
    list-style: none;
    border-radius: 10px;
    cursor: pointer;
}

.tutor-dash .menu .menu-top {
    height: 40px;
    width: 60%;
    margin: 0 auto !important;
    background-color: #099d14;
    border-radius: 0 0 250px 250px;
}

.tutor-dash .menu .active-tab {
    color: white;
    background-color: #099d14 !important;
}

/* tutor home  */
.tutor-home .banner-box {
    width: 100%;
    background-color: #ffcb00;
    border-radius: 20px;
}

.tutor-home .banner-bottom {
    height: 10px;
    width: 90%;
    border-radius: 0 0 25px 25px;
    background-color: #e0b402;
}

.tutor-home .analytic-data {
    border-radius: 10px;
    border: 2px solid rgb(240, 237, 237);
}

.alert-class {
    background-color: #ff5757;
    padding: 10px 20px !important;
    border-radius: 10px;
}

/* Notification  */
.btn-new-notify {
    background-color: #ffcb00;
    border-radius: 30px;
}

.btn-detail {
    background-color: #20bef8;
    border-radius: 5px;
}

.btn-read {
    background-color: #d3d9db;
    border-radius: 30px;
}

.btn-unread {
    color: #0fa5db !important;
    background-color: #cce7f1;
    border-radius: 30px;
}

.btn-transferred {
    background-color: #089D121A;
    color: #089D12;
    font-size: 16px;
    border-radius: 30px;
}

.border-orange {
    border: 1px solid #ffcb00;
    border-radius: 10px;
}

.tutor-dash .notify-card:hover {
    border: 1px solid #20bef8;
}


/* My Profile  */
.tutor-dash .my-address {
    background-color: #f4f6fb;
}

.tutor-dash .border-dot {
    border: 1px dotted rgb(175, 174, 174);
}

/* calendar  */
.cl-body {
    background-color: #f4f6fb !important;
    border: none !important;
    padding: 10px 0 !important;
}

.tilename {
    padding: 10px !important;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
    /* background-color: #20a5e27a !important; */
    background-color: #ffcb00 !important;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
    background-color: #ffcc0086 !important;

    border-radius: 10px;
    color: white !important;
}

.react-calendar__tile--active {
    border: 2px solid #ffcb00 !important;
    background-color: #ffcb00 !important;
    color: #ffcb00 !important;
    border-radius: 10px;
}

.react-calendar__tile--active:enabled:hover {
    background-color: #ffcb00 !important;
}

.overlay-card {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 10px 10px 25px #00000033;
    position: absolute;
    z-index: 2;
}

.overlay-card:before {
    content: '';
    position: absolute;
    left: 50%;
    right: 50%;
    bottom: 0;
    top: -10px;
    margin: 0 auto;
    width: 0;
    height: 0;
    border-bottom: 10px solid white;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
}

.reshedule-btn {
    background-color: rgb(241, 226, 229);
    color: rgb(240, 44, 83);
    border-radius: 4px;
}

.time-slot-container .time-slots-booked {
    border-radius: 10px;
    background-color: #FC595B1A;
    color: #FF5757;
}

.time-slot-container .time-slots-available {
    border-radius: 10px;
    background-color: #089D121A;
    color: #089D12;
}

.time-slot-container .time-slots-unavailable {
    border-radius: 10px;
    background-color: #EAEAEA;
    color: #434943;
}

/* library  */
.tutor-library .input-box {
    box-shadow: 1px 1px 7px 2px #e9e5e5;
    border: none;
    outline: none;
    border-radius: 10px;
    height: 49px !important;
}

.tutor-library .course-card:hover {
    border: 1px solid green;
}

/* My student  */
.tutor-dash .chat-student-card:hover {
    border: 1px solid #20bef8;
    cursor: pointer;
}

.tutor-dash .active-student-card {
    border: 1px solid #20bef8;
    cursor: pointer;
}

.btn-report {
    border-radius: 13px;
    background-color: crimson;
    box-shadow: 0 4px 2px rgb(190, 52, 80);
    color: white;
    font-family: "Roboto", sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    padding: 6px 10px !important;
}

.btn-join {

    background-color: #20bef8;
    box-shadow: 0 4px 2px #28aedf;
    color: white;
    font-family: "Fredoka One", cursive;
}

/* My chat  */
.chat-box .chat-header {
    background-color: rgb(233, 227, 227);
    border-radius: 10px 10px 0 0;
}

.chat-box .chat-body {
    background-color: #F1F3F7;
    height: calc(100vh - 240px);
}


/* Support  */
.tutor-support .banner-box-support {
    /* height: 270px; */
    width: 100%;
    background-color: #20bef8;
    border-radius: 20px;
}

.tutor-support .banner-bottom-support {
    height: 10px;
    width: 90%;
    border-radius: 0 0 25px 25px;
    background-color: #169fd1;
}

/* Faqs  */
.tutor-dash .back-btn {

    color: white !important;
    background-color: #089D12 !important;
    font-size: 14px !important;
    font-family: "MuseoSansRounded900", regular !important;
    border-radius: 10px;
    letter-spacing: 0.84px;
    text-transform: uppercase;
    height: 39px;
    width: 93px;
}

/* opentickets  */
.btn-new-msg {
    background-color: #FF57571A;
    border-radius: 20px;
    color: #FF5757;
}

.btn-resolved {
    background-color: #089D121A;
    border-radius: 20px;
    color: #089D12;
}


/* switch  */
.switch {
    position: relative;
    display: inline-block;
    width: 35px;
    height: 20px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 15px;
    width: 15px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

.switch input:checked+.slider {
    background-color: #099d14;
}

.switch input:focus+.slider {
    box-shadow: 0 0 1px #099d14;
}

.switch input:checked+.slider:before {
    -webkit-transform: translateX(15px);
    -ms-transform: translateX(15px);
    transform: translateX(15px);
}


/* Rounded sliders */
.slider.round {
    border-radius: 10px;
}

.slider.round:before {
    border-radius: 50%;
}

@media (max-width:850px) {
    .tutor-home .banner-box {
        height: auto;
    }

    .banner-box-support {
        height: auto;
    }
}

/* attendance  */

.DateInput_input {
    font-weight: 200;
    font-size: 16px;
    line-height: 14px;
    color: #484848;
    background-color: #fff;
    width: 100%;
    padding: 5px 5px;
}

.DateInput {
    width: 100px;
}

.CalendarDay__hovered_span,
.CalendarDay__hovered_span:hover {
    background-color: #F24969;
    border: 1px double #F24969;
    color: white;
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
    background-color: #F24969;
    border: 1px double #F24969;
    color: white;
}

.CalendarDay__selected_span {
    background-color: #F24969;
    border: 1px double #F24969;
    color: #fff
}

.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover {
    background-color: #db6d81;
    border: 1px double #e67e91;
    color: #fff
}

/* Payout */
.tutor-payout .edit-info{
    cursor:initial !important;
    box-shadow: none;
    transform: scale(1) !important;
}

/* switch complete  */
@media (max-width:600px) {
    .tutor-dash .left-dash {
        height: 100%;
    }

    .tutor-dash {
        height: 100%;
    }

    .tutor-dash .right-dash {
        height: 100%;
    }

    .tutor-home .banner-box {
        height: auto;
    }

    .banner-box-support {
        height: auto;
    }

    .tutor-dash .fs-24 {
        font-size: 18px;
    }

    .tutor-dash .fs-14 {
        font-size: 12px;
    }
}