.subscription-body {
    background-color: white;
    box-shadow: 0 2px 3px rgb(243, 236, 236);
    border-radius: 10px;
}

.subscription-body select {
    border: 2px solid rgb(214, 209, 209);
    border-radius: 10px;
    height: 70px;
}

.subscription-body select:hover {
    border: 2px solid #16ac20a6;
}

.subscription-body ul li {
    list-style: none;
    margin: 10px 0 !important;
}

.subscription-body ul li {
    list-style: none;
}

.subscription-plan {
    padding: 0 20px !important;
    margin-bottom: 20px !important;
    border-radius: 10px;
    border: 2px solid rgb(214, 209, 209);
}

.subscription-plan:hover {
    border: 2px solid #16ac20a6;
}

.subscription-plan.active-plan {
    border: 2px solid #16ac20a6 !important;
}

.plan-tag {
    background-color: #FF5757;
    border-radius: 0 10px 0 10px;
    position: absolute;
    top: -2px;
    right: -2px;
    color: white;
}

.btn-select-plan {
    background-color: #FF5757;
    color: white;
    border-radius: 5px;
    padding: 15px 10px !important;
}

.btn-disabled-select-plan {
    background-color: #a39f9fc0;
    color: white;
    border-radius: 5px;
    padding: 15px 10px !important;
}

.payment-gateway {
    border: 2px solid rgb(233, 226, 226) !important;
    border-radius: 10px;

}

.payment-gateway-active {
    border: 2px solid #16ac20a6 !important;
    background-color: #199B211A;
}