.checkout {
  min-height: 100vh;
  height: 100%;
  width: 100%;
  background-color: #2e6cb5;
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkout-box {
  display: flex;
  width: 60%;
  min-height: 90vh;
  height: 100%;
  border-radius: 20px;
  background-color: white;
}

.left-checkout {
  border-radius: 20px 0 0 20px;
  width: 40%;
  background-image: linear-gradient(45deg, #384c64, #2b3d50);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.right-checkout {
  padding: 20px 40px !important;
  width: 60%;
  height: 100%;
}

.bg-heading {
  color: #384c64;
  font-family: "MuseoSansRounded500";
}

.sm-heading {
  color: var(--color-light-grey);
  font-family: "MuseoSansRounded500";
}

.checkout .input-label {
  font-family: "MuseoSansRounded500";
  margin-bottom: 5px !important;
  display: block;
  opacity: 0.8;
}

.checkout .input-box {
  font-family: "MuseoSansRounded500";
  box-shadow: 0px 5px 20px #00000014;
  border: none;
  outline: none;
  border-radius: 10px;
  height: 49px;
  font-size: 18px;
  width: 100%;
  border: 1px solid #eaeaea !important;
}
.checkout .input-box-card {
  font-family: "MuseoSansRounded500";
  /* box-shadow: 0px 5px 20px #00000014; */
  border: none;
  outline: none;
  border-radius: 10px 0 0 10px;
  height: 49px;
  font-size: 18px;
  width: 100%;
  border-left: 1px solid #eaeaea !important;
}

.checkout .btn-pay {
  background-color: #4a91e3;
  border-radius: 10px;
  font-family: "MuseoSansRounded900";
  width: 100%;
  padding: 10px 20px !important;
  color: white;
}

.checkout .pay-amount {
  color: white;
  font-family: "MuseoSansRounded500";
}

.checkout .cancel-pay {
  padding: 10px !important;
  background-color: #0c2543;
  color: white;
  font-family: "MuseoSansRounded500";
  cursor: pointer;
  width: 100%;
  text-align: center;
  border-radius: 0 0 0 20px;
}

/* responsive */
@media (max-width: 1000px) {
  .checkout-box {
    width: 90%;
    min-height: 90vh;
    height: 100%;
  }
}

@media (max-width: 650px) {
  .checkout {
    background-color: #ffffff;
  }
  .checkout-box {
    flex-direction: column;
    width: 100%;
    min-height: 90vh;
    height: 100%;
  }
  .left-checkout {
    display: none;
  }
  .left-checkout img {
    width: 100% !important;
    /* height: 200px; */
    /* display: block;
        display: none; */
  }
  .right-checkout {
    width: 100%;
  }
}

.custom_date_picker {
  width: 100%;
}

.custom-date-wrapper {
  text-align: left; /* Ensures wrapper alignment */
}

input[type="date"].custom_date_picker {
  text-align: left; /* Align text to the start */
  direction: ltr;
  appearance: none; /* Removes native styles */
}

input[type="date"].custom_date_picker::-webkit-datetime-edit {
  text-align: left;
  padding-left: 4px; /* Adjust as needed */
}

input[type="date"].custom_date_picker::-webkit-datetime-edit-fields-wrapper {
  text-align: left;
}

input[type="date"].custom_date_picker::-webkit-datetime-edit-text {
  text-align: left;
}

input[type="date"].custom_date_picker::-webkit-inner-spin-button,
input[type="date"].custom_date_picker::-webkit-clear-button {
  display: none;
}

/* @media (max-width: 320px) {
  .custom_date_picker {
    min-width: 200px !important;
  }
}
@media (max-width: 576px) {
  .custom_date_picker {
    min-width: 335px;
  }
} */
