.chat-backdrop {
  background-color: rgba(85, 83, 83, 0.589);
}

#chat-pop-body::-webkit-scrollbar {
  display: none;

}

#chat-pop-body {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}