.tx-yellow {
  color: var(--color-yellow);
}

.bg-yellow {
  background-color: var(--color-yellow);
}

.tx-green {
  color: var(--color-green);
}

.tx-red {
  color: #f10d0dd0;
}

.tx-lt-black {
  color: var(--color-light-black);
}

.tx-lt-black-op-5 {
  color: var(--color-light-black);
  opacity: 0.5;
}

.bg-green {
  background-color: #0b770b;
}

.bg-sky {
  background-color: var(--color-sky);
}

.tx-sky {
  color: var(--color-sky);
}

.tx-error {
  color: crimson;
}

.tx-grey {
  color: var(--color-grey);
}

.tx-lt-grey {
  color: var(--color-light-grey);
}

.tx-black {
  color: #000000;
}

.bg-teal {
  background-color: #dee4e6;
}

.h-85 {
  height: 85%;
}

.b-grey {
  border: 1px solid #e7e3e3;
  border-radius: 10px;
}

.overflow-elipse {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.overflow-text {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.overflow-text-6 {
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* button  */
.btn-green {
  background-color: var(--color-green);
  color: white;
  border-radius: 10px;
}

.btn {
  transition: 0.5s;
}

.btn:active {
  transform: scale(0.97);
}

/* fontsize  */
.fs-10 {
  font-size: 10px;
}

.fs-12 {
  font-size: 12px;
}

.fs-11 {
  font-size: 11px;
}

.fs-14 {
  font-size: 14px;
}

.fs-16 {
  font-size: 16px;
}

.fs-18 {
  font-size: 18px;
}

.fs-20 {
  font-size: 20px;
}

.fs-22 {
  font-size: 22px;
}

.fs-24 {
  font-size: 24px;
}

.fs-26 {
  font-size: 26px;
}

.fs-30 {
  font-size: 30px;
}

.fs-32 {
  font-size: 32px;
}

.fs-40 {
  font-size: 40px;
}

.fs-42 {
  font-size: 42px;
}

.fs-45 {
  font-size: 45px;
}

.fs-61 {
  font-size: 61px;
}

.fs-31 {
  font-size: 31px;
}

.lh-26 {
  line-height: 26px;
}

.lh-21 {
  line-height: 21px;
}

.lh-30 {
  line-height: 30px;
}

.lh-18 {
  line-height: 18px;
}

.lh-15 {
  line-height: 15px;
}

.text-upper {
  text-transform: uppercase;
}

.text-lower {
  text-transform: lowercase;
}

.f-robo {
  font-family: "Roboto", sans-serif;
}

.f-fred {
  font-family: "Fredoka One", regular;
}

.f-lily {
  font-family: 'Lilita One', cursive;
}

.bold {
  font-weight: bold;
}

p {
  margin: 0;
  padding: 0;
  word-wrap: break-word;
  max-width: 900px;
}

.br-2 {
  border-radius: 20px;
}

.br-1 {
  border-radius: 10px;
}

.border-sky {
  border: 3px solid var(--color-green);
}

.border-yellow {
  border: 2px solid var(--color-yellow);
}

/* position  */
.p-rel {
  position: relative;
}

.p-abs {
  position: absolute;
}

.p-sticky {
  position: sticky;
}

.p-fix {
  position: fixed;
}

.tp-0 {
  top: 0;
}

.bt-0 {
  bottom: 0;
}

.lt-0 {
  left: 0;
}

.rt-0 {
  right: 0;
}

.all-center {
  justify-content: center;
  align-items: center;
  display: flex;
}

.v-center {
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.h-center {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.mw-100 {
  max-width: 100%;
}

.br {
  border: 2px solid #ff0000;
}

.error-border {
  border: 2px solid #ff0000 !important;
}

.success-border {
  border: 2px solid var(--color-green) !important;
}

.bg-lt-grey {
  background-color: #b8b4b423;
}

/* z-index  */
.z-index-1 {
  z-index: 1;
}

.z-index-2 {
  z-index: 2;
}

.pointer {
  cursor: pointer;
}

.clear {
  clear: both;
  display: block;
}

.wrap {
  white-space: pre-wrap;
}

/* text-decoration  */
.decoration-line {
  text-decoration: underline;
}

.decoration-inline {
  text-decoration: line-through;
}

.decoration-none {
  text-decoration: none;
}

a {
  text-decoration: none !important;
  color: inherit !important;
}

.link {
  color: #0b89b7 !important;
}
.link-black{
  color: black !important;
  cursor: pointer;
}

.link:hover {
  border-bottom: 2px dotted #516fd3;
  color: rgb(151, 149, 149);
}


.link-hover:hover {
  border-bottom: 2px dotted #516fd3;
  color: rgb(151, 149, 149);
}

.ls {
  letter-spacing: 0.96px
}

.sub-heading {
  color: #434943;
  font-family: "MuseoSansRounded700", regular;
  font-size: 14px;
}

/* //Radio  */
.radio-item,
.radio-ar-item {
  display: inline-block;
  position: relative;
  padding: 0 6px;
  margin: 10px 0 0;
  transition: 0.4s;
}


.radio-item input[type='radio'],
.radio-ar-item input[type='radio'] {
  display: none;
}

.radio-item label,
.radio-ar-item label {
  color: rgb(177, 176, 176);
  transition: 0.4s;
}

.radio-item label:before,
.radio-ar-item label:before {
  content: " ";
  display: inline-block;
  position: relative;
  top: 5px;
  margin: 0 5px 0 0;
  width: 20px;
  height: 20px;
  border-radius: 11px;
  border: 2px solid var(--color-green);
  background-color: transparent;
}


.radio-item input[type=radio]:checked+label:after {
  border-radius: 11px;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 9px;
  left: 4px;
  content: " ";
  display: block;
  background: var(--color-green);
}

.radio-ar-item input[type=radio]:checked+label:after {
  border-radius: 11px;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 9px;
  right: 9px !important;
  content: " ";
  display: block;
  background: var(--color-green);
  ;
}

.radio-item input[type=radio]:checked+label,
.radio-ar-item input[type=radio]:checked+label {
  color: rgb(58, 57, 57);
}


.check-item {
  display: inline-block;
  position: relative;
  padding: 0 6px;
  margin: 10px 0 0;
  transition: 0.4s;
}

.check-item input[type='radio'] {
  display: none;
}

.check-item label {
  color: rgb(177, 176, 176);
  transition: 0.4s;
}

.check-item label:before {
  content: " ";
  display: inline-block;
  position: relative;
  top: 5px;
  margin: 0 5px 0 0;
  width: 20px;
  height: 20px;
  border-radius: 11px;
  border: 2px solid var(--color-green);
  background-color: transparent;
}

.check-item input[type=radio]:checked+label:after {
  border-radius: 11px;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 9px;
  left: 4px;
  content: " ";
  display: block;
  background: var(--color-green);
}

.check-ar-item input[type=radio]:checked+label:after {
  border-radius: 11px;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 9px;
  right: 4px !important;
  content: " ";
  display: block;
  background: var(--color-green);
}

.check-item input[type=radio]:checked+label {
  color: #3a3939;
}

::placeholder {
  color: #C3C3C3;
  font-family: "MuseoSansRounded700", regular;
  font-size: 14px;
}

.react-stars {
  direction: ltr !important;
}

/* Direction */
.rtl {
  direction: rtl !important;
}

.ltr {
  direction: ltr !important;
}

.dir-initial {
  direction: initial !important;
}

/* margins  */
.cmx-2 {
  margin-left: 10px;
  margin-right: 10px;
}

.cmy-2 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.cmx-4 {
  margin-left: 20px !important;
  margin-right: 20px !important;
}

.cmy-4 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.cpx-2 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.cpy-2 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.cpx-4 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.cpy-4 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

/* priyanka popup  */
.faded {
  z-index: 3;
  height: 100vh;
  width: 100%;
  position: fixed;
  background-color: #9c9a9a99;
  top: 0;
  right: 0;
  overflow-y: auto;
}

/* right side pop-up css  */
.rightpop-up {
  z-index: 3;
  height: 100vh;
  border-radius: 20px 0px 0px 20px;
  width: 35%;
  min-width: 300px;
  background-color: #ffffff;
  position: absolute;
  top: 0;
  right: 0;
  overflow-y: auto;

}

.centerpop-up {
  z-index: 3;
  position: absolute;
  top: 25%;
  right: 33%;
  background-color: #ffffff;
  border-radius: 20px 20px 20px 20px;
  height: 55%;
  width: 30%;
  color: black;
}

.img.overlay {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

@media (max-width: 600px) {
  p {
    max-width: 600px;
  }

  .cmx-2,
  .cmx-4 {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }

  .cmy-2,
  .cmy-4 {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }

  .cpx-2,
  .cpx-4 {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .cpy-2,
  .cy-4 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }

  .overflow-elipse {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;

  }

  .p-4 {
    padding: 20px !important;
  }

  .p-5 {
    padding: 20px !important;
  }

  .rightpop-up {
    z-index: 3;

    border-radius: 0px;
    width: 100%;

    overflow-y: auto;

  }
}

/* Date input   */
.DateInput_input {
  font-weight: 200;
  font-size: 16px;
  line-height: 14px;
  color: #484848;
  background-color: #fff;
  width: 100%;
  padding: 5px 5px;
}

.DateInput {
  width: 120px;
  height: 100%;
  padding: 5px 5px !important;
  margin: initial !important;
}

.CalendarDay__hovered_span,
.CalendarDay__hovered_span:hover {

  background-color: #20bef8;
  border: 1px double #20bef8;
  color: white;
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background-color: #20bef8;
  border: 1px double #20bef8;
  color: white;
}

.CalendarDay__selected_span {
  background-color: #20bef8;
  border: 1px double #20bef8;
  color: #fff
}

.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover {
  background-color: #20bef8a4;
  border: 1px double #20bef8a9;
  color: #fff
}
.CalendarMonth_table{
  margin-right: 10px !important;
}
.react-calendar__month-view__days__day--neighboringMonth.react-calendar__month-view__days__day--weekend{
  color: #757575;
}


/* ---------------------------  */
.PresetDateRangePicker_panel {
  padding: 0 22px 11px !important
}

.SingleDatePickerInput_calendarIcon {
  padding: 10px !important;
  margin: 0 5px 0 10px !important
}

/* custom dropdown  */
.c-dropdown-body {
  box-shadow: 0 0 10px 3px rgba(204, 202, 202, 0.575);
  display: none;
  transition: 0.5s;
  z-index: 2;
  top: 40px;
}

.c-dropdown-body ul li,
.dropdown-body ul li {
  margin: 0px !important;
  padding: 5px 10px !important;
  transition: 0.3s;
}

.c-dropdown-body ul li label,
.dropdown-body ul li {
  cursor: pointer;
  color: #434943b6;
}

.c-dropdown-body ul li:hover,
.dropdown-body ul li:hover {
  background-color: #d4d9db96;
}

.c-drop-icon:checked~.c-dropdown-body {
  display: initial;
}


/* React calendar  */
.CalendarMonth_caption {

  padding-top: 22px !important;
  padding-bottom: 37px !important;
}

.CalendarMonth_caption__verticalScrollable {
  padding-top: 12px !important;
  padding-bottom: 7px !important;
}

.DateRangePickerInput_calendarIcon {
  padding: 10px !important;
  margin: 0 5px 0 10px !important;
}

/* React multi select dropdown  */
.msl-input-wrp {
  text-transform: capitalize;
  font-family: "MuseoSansRounded500", regular;
  padding: 3px 10px !important;
}

.msl-input {
  text-transform: capitalize;
  padding: 3px 10px !important;
  font-size: 20px;
  font-family: "MuseoSansRounded500", regular;
}

.msl-single-value {
  height: 100%;
  padding: 6px 10px !important;
  margin-right: 10px !important;
  max-width: 100%;
}

/* Toast */
.toast-body {
  box-shadow: 0px 5px 20px #00000014;
  border-radius: 10px;
  border: 1px solid #4543432b;
}

/* Hide eye from input from microsoft */
input::-ms-reveal,
input::-ms-clear {
  display: none;
}

/* React-tostify */
.Toastify__toast {
  padding: 10px !important;
  margin: 0 0 5px 0 !important;
}

.Toastify__toast-icon {
  -webkit-margin-end: 10px !important;
  margin-inline-end: 10px !important;
  width: 20px !important;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  display: -ms-flexbox;
  display: flex;
}

/* Multi-select */
.msl-input-wrp {
  cursor: pointer;
}

textarea {
  resize: none;
}

/* MUI ToolTIP */
.MuiTooltip-popper div {
  background-color: var(--color-yellow);
  padding: 5px 8px !important;
  margin: 5px !important;
  font-size: 14px;
}

