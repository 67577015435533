/* Header style */
.web-header {
    padding: 20px 0 !important;
    position: absolute;
    top: 0;
    z-index: 1;
    width: 100%;
}

.web-header .navigation {
    text-align: right;
}

.web-header .navigation ul {
    list-style: none;
    line-height: 68px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.web-header .navigation ul li {
    display: inline-block;
    margin-left: 20px;
    text-transform: uppercase;
    font-family: "Fredoka One", regular;
    font-size: 18px;
    color: var(--color-light-black);
    cursor: pointer;
}

.web-header .web-login {
    border-radius: 13px;
    background-color: #20bef8;
    box-shadow: 0 4px 2px #28aedf !important;
    font-family: "Fredoka One", cursive;
    text-transform: uppercase;
    padding: 6px 15px !important;
    font-size: 14px;
    height: 49px;
    letter-spacing: 0.84px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white !important;
}

/* Top Home style */
.banner-section {
    min-height: 948px;
    width: 100%;
    background: transparent url('../images/home/Mask\ Group\ 47.svg') 0% 0% no-repeat padding-box;
    background-position-y: -200px;
}

.learn-arabic-point ul {
    margin: initial !important;
    margin-left: 20px !important;
    margin-right: 20px !important;
    padding-left: initial !important;
    list-style:none;
}

.learn-arabic-point ul li {
    margin: initial !important;
    margin-left: 20px !important;
    margin-right: 20px !important;
    padding-left: initial !important;
}
.learn-arabic-point ul p{
    margin-left: 10px !important;
    margin-right: 10px !important;
}

.banner-body,
.margin-top {
    margin-top: 150px !important;
}

.banner-sub-heading {
    margin-top: 20px !important;
    text-align: left;
    font: normal normal normal 24px/34px MuseoSansRounded500;
    letter-spacing: 0px;
    color: var(--color-light-grey);
    max-width: 75%;
}

.find-tutor-btn {
    border-radius: 13px;
    background-color: #58cc24;
    box-shadow: 0 4px 2px #58A71C !important;
    color: white;
    font-family: "Fredoka One", cursive;
    text-transform: uppercase;
    padding: 6px 15px !important;
    font-size: 14px !important;
    height: 49px;
    letter-spacing: 0.84px;
    width: 260px;
}

.web-heading {
    font: normal normal normal 50px/58px Fredoka One;
    letter-spacing: 0px;
    color: var(--color-light-grey);
}

.web-sub-heading {
    margin-top: 20px !important;
    text-align: left;
    font: normal normal normal 24px/34px MuseoSansRounded500;
    letter-spacing: 0px;
    color: var(--color-light-grey);
    /* opacity: 0.7; */
}

.web-point-heading {
    margin-top: 20px !important;
    font: normal normal normal 24px/24px Fredoka One;
    letter-spacing: 0px;
    color: var(--color-light-grey);
}

.banner-section .banner-video {
    background: #FEC92E 0% 0% no-repeat padding-box;
    box-shadow: 0px 20px 40px #FEC92E66;
    border-radius: 80px 300px 120px 120px;
    width: 100%;
    height: 600px;
}

.banner-section .banner-video video {
    border-radius: 80px 300px 80px 80px !important;
    width: 100%;
    height: 98%;
    object-fit: fill;
}

.banner-parrot-body {
    position: absolute;
    right: -20px;
    bottom: -60px;
}

.banner-parrot {
    width: 120px;
}

/* Section 2 */
.sec2-img {
    max-width: 100%;
    margin: 50px 0 !important;
}

.testimonial-view {
    margin-top: 5px !important;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.testimonial-view img {
    width: 66px;
    height: 66px;
    border-radius: 100%;
    margin: 20px 20px 20px 0px !important;

}

.testimonial-view-text {
    font: normal normal normal 14px/24px MuseoSansRounded500;
    letter-spacing: 0px;
    color: #1B221B;
}

.testimonial-view-author {
    font: normal normal normal 14px/24px MuseoSansRounded700;
    letter-spacing: 0px;
    color: #1B221B;
    opacity: 0.7;
}


/* section 3 */
.section-3 {
    background-image: url('../images/home/BG\ Yellow.svg');
    background-size: cover;
    background-position: top;
    margin: 50px 0 !important;
    height: 1300px;
    padding: 50px 0 !important;
    overflow: hidden;
    display: flex;
    align-items: center;
    width: 100%;
}


.section-3 .tutor-img {
    transform: matrix(0.94, -0.34, 0.34, 0.94, 0, 0);
    border-radius: 30px;
    max-width: 170%;
    object-fit: fill;
}

.section-3 .tutor-detail-img {
    transform: matrix(0.94, -0.34, 0.34, 0.94, 0, 0);
    border-radius: 30px;
    max-width: 170%;
    object-fit: fill;
}



/* section 4 */
.section-4 {
    margin: 50px 0 !important;
    margin-top: 200px !important;
}

.section-4 p {
    width: 80%;
}

.girl-img-box {
    position: relative;
    width: 400px;
    height: 400px;
    background-color: var(--color-sky);
    border-radius: 100%;
}

.girl1-img {
    position: absolute;
    bottom: -60px;
    left: -40px;
    max-width: 150%;
    margin: 50px 0 !important;
}

.sec4-quote-img {
    max-width: 100%;
    width: 122px;
    height: 98px;

}


/* section 5 */
.section-5 {
    margin: 50px 0 !important;
}

.section-5-box {
    padding-top: 20px !important;
    background: #F4F4F4 0% 0% no-repeat padding-box;
    border-radius: 32px;
}

.section-5-box .left {
    padding: 50px 20px !important;
}


.section-5 input {
    width: 335px;
    max-width: 100%;
    height: 49px;
    background: #F9F9F9 0% 0% no-repeat padding-box;
    border: 1px solid #EAEAEA;
    border-radius: 15px;
    outline: none;
    padding: 10px !important;
}

.girl2-img-box {
    position: relative;
    width: 400px;
    height: 400px;
    display: flex;
    align-items: center;
}

.cloud-img {
    position: absolute;
    top: 40px;
    z-index: 0;
}

.girl2-img {
    z-index: 1;
    position: relative;
}

/* footer */
.web-footer {
    padding: 20px 20px 0 20px !important;
    background: #F4F4F4 0% 0% no-repeat padding-box;
    border-radius: 32px;
}

.web-footer .footer-quote {
    height: 56.1px;
}

.web-footer .logo {
    height: 60.93px;
}

.web-footer ul {
    list-style: none;
}

.web-footer .footer-navigation ul li {
    display: inline-block;
    margin-right: 20px !important;
    text-transform: uppercase;
    font-family: "Fredoka One", regular;
    color: var(--color-light-black);
    cursor: pointer;
    margin-bottom: 10px !important;
}

.web-footer .footer-navigation-icon ul li {
    display: inline-block;
    margin-right: 10px !important;
    text-transform: uppercase;
    font-family: "Fredoka One", regular;
    color: var(--color-light-black);
    cursor: pointer;
}

.web-footer .footer-legal-link ul li {
    display: inline-block;
    transition: 0.4s;
    margin-right: 30px !important;
    text-transform: uppercase;
    font-size: 12px;
    font-family: "MuseoSansRounded500";
    font-weight: bold;
    color: var(--color-light-black);
    cursor: pointer;
    margin-bottom: 10px !important;
}

.web-footer .dhad-right {
    text-transform: uppercase;
    font-family: "MuseoSansRounded500";
    color: var(--color-light-black);
    font-weight: bold;
    font-size: 12px;
}

/* tutor knowledge */
.web-tutor-knowledge .banner-section {
    height: 777px;
}

.banner-img {
    background: #FEC92E 0% 0% no-repeat padding-box;
    box-shadow: 0px 20px 40px #FEC92E66;
    border-radius: 80px 300px 120px 120px;
    width: 100%;
    height: 600px;
}

.web-tutor-section-3 {
    background-image: url('../images/BG\ sky.svg');
    background-size: cover;
    background-position: top;
    height: 1150px;
    margin: 50px 0 !important;
    overflow: hidden;
    display: flex;
    align-items: center;
    width: 100%;
}

.web-tutor-section-3 ul li {
    list-style: none;
    font: normal normal normal 24px/34px MuseoSansRounded500;
    color: white;
    opacity: 0.7;
}

.web-tutor-section-3-body {

    /* background-color: var(--color-sky); */
    margin: 0px !important;
}

/* Navigation effect */
.web-footer ul li:hover,
.navigation ul li:hover {
    opacity: 0.7;
}

.active-web-link {
    color: var(--color-green) !important;
}
@media (max-width : 650px) {
    .web-header .web-login {
        border-radius: 10px;
        padding: 10px 5px !important;
        height: 39px !important;
    }
}

@import url('./homeResponsive.css');