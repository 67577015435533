@media (max-width : 1400px) {
    .web-heading {
        font: normal normal normal 40px/38px Fredoka One !important;
       line-height:normal !important;
    }
    .banner-sub-heading{
      
        font: normal normal normal 20px/30px MuseoSansRounded500 !important;  
    }
    .banner-section {
        height: auto !important;
    }

    .web-sub-heading {
        margin-top: 10px !important;
        text-align: left;
        font: normal normal normal 20px/30px MuseoSansRounded500 !important;
    }
    .web-point-heading {
        margin-top:10px !important;
        font: normal normal normal 18px/24px Fredoka One !important;
    }
    .banner-parrot-body {
        position: absolute;
        right:-20px;
        bottom: -100px !important;
    }
}
/* Media query for mobile screen < 650px */
@media (max-width : 650px) {
    .web-header-body {
        margin: 0 20px !important;
    }

    .web-header .logo {
        height: 40px !important;
    }

    .web-header .web-login {
        border-radius: 10px;
        padding: 10px 5px !important;
        height: 39px !important;
    }

    /* Top Home style */
    .banner-section {
        height: auto !important;
        background: transparent url('../images/home/Mask\ Group\ 47.svg') 0% 0% no-repeat padding-box;
        background-position-y: -200px;
    }

    .banner-sub-heading {
        max-width: 98% !important;
    }

    .banner-body {
        padding: 0 20px !important;
    }

    .banner-body img {
        width: 40px;
    }
    .banner-sub-heading{
      
        font: normal normal normal 18px/30px MuseoSansRounded500 !important;  
    }

    .banner-body,
    .margin-top {
        margin-top: 100px !important;
    }

    .web-heading {
        margin-top: 10px !important;
        font: normal normal normal 30px/38px Fredoka One !important;
    }

    .web-sub-heading {
        margin-top: 10px !important;
        text-align: left;
        font: normal normal normal 18px/24px MuseoSansRounded500 !important;
    }
    .web-point-heading {
        margin-top: 10px !important;
        font: normal normal normal 16px/20px Fredoka One !important;
    }

    .banner-section .banner-video {
        height: 300px !important;
        border-radius: 150px 500px 150px 150px !important;
        
    }
   
    .banner-section .banner-body video{
        border-radius: 80px 400px 80px 80px !important;
        object-fit: fill !important;
    }
    .banner-parrot-body {
        position: absolute;
        right:-10px !important;
        bottom: -40px !important;
    }

    /* Section 2,3 */
    .section-2,
    .section-3,
    .section-4 {
        padding: 0 20px !important;
    }



    /* section 4 */
    .section-4 p {
        width: 100% !important;
    }

    .girl-img-box {
        position: relative;
        width: 230px !important;
        height: 230px !important;
        background-color: var(--color-sky);
        border-radius: 100%;
    }

    .girl1-img {
        position: absolute;
        bottom: -40px;
        left: -50px;
        max-width: 150%;
        margin: 30px 0 !important;
    }

    .sec4-quote-img {
        max-width: 100%;
        width: 40px !important;
        height: auto !important;
        margin-top: 20px !important;
    }

    /* Section 5 */
    .section-5-box {
        padding-bottom: 20px !important;
    }

    .section-5 p {
        width: 100%;
    }


    .girl2-img-box {
        position: relative;
        width: 100% !important;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        padding-left: 30px !important;
    }

    .cloud-img {
        position: absolute;
        top: 40px;
        z-index: 0;
    }

    .girl2-img {
        z-index: 1;
        position: relative;
        width: 230px;
    }

    /* Footer */
    .web-footer .footer-quote {
        width: 40px !important;
    }

    .web-footer .logo {
        height: 50px;
        display: inline-block;
    }

    /* Tutor knowledge */
    .web-tutor-knowledge .tutor-img {
        width: 100% !important;
        border-radius: 60px 200px 80px 60px !important;

    }

    .web-tutor-knowledge .banner-section {
        height: auto !important;
    }
    .banner-img {
        background: #FEC92E 0% 0% no-repeat padding-box;
        box-shadow: 0px 20px 40px #FEC92E66;
        border-radius: 80px 300px 120px 120px;
        width: 100%;
        height: 95% !important;
    }
    .web-tutor-section-3-body{
        padding-left: 20px !important;
    }



}