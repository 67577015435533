.popup-modal {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 5;
  background-color: #727272cc;
}

.popup-modal-body {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 4px rgb(235, 233, 233);
  min-height: 20%;
  max-height: 90%;
  overflow-y: auto;
}

.left-popup {
  background-color: white;
  border-radius: 10px 0 0 10px;
  box-shadow: 0 0 4px rgb(235, 233, 233);
  /* width:430px; */
  width: 35%;
  min-width: 300px;
  height: 100vh;
  overflow-y: auto;
}

.popup-modal .popup-modal-body.xsm {
  width: 20%;
}

.popup-modal .popup-modal-body.sm {
  width: 35%;
}

.popup-modal .popup-modal-body.md {
  width: 60%;
}

.popup-modal .popup-modal-body.lg {
  width: 80%;
}

.popup-modal .popup-modal-body.xlg {
  width: 90%;
}

.css-1dimb5e-singleValue {
  padding-left: 8px !important;
}

.css-13cymwt-control {
  -webkit-box-align: center;
  align-items: center;
  cursor: default;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  justify-content: space-between;
  min-height: 49px !important;
  position: relative;
  transition: 100ms;
  background-color: transparent !important;
  border-color: transparent !important;
  border-radius: 4px ;
  border-style: none !important;
  border-width: 0px !important;
  box-sizing: border-box;
  outline: 0px !important;
}

.css-t3ipsp-control {
  -webkit-box-align: center;
  align-items: center;
  cursor: default;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  justify-content: space-between;
  min-height: 49px !important;
  position: relative;
  transition: 100ms;
  background-color: transparent !important;
  border-color: transparent !important;
  border-radius: 4px ;
  border-style: none !important;
  border-width: 0px !important;
  box-sizing: border-box;
  outline: 0px !important;
  box-shadow: rgba(38, 132, 255, 0) 0px 0px 0px 0px !important;
}
.css-t3ipsp-control:hover {
  border-color: transparent !important;
}

.css-19bb58m {
  visibility: visible;
  flex: 1 1 auto;
  display: inline-grid;
  grid-area: 1 / 1 / 2 / 3;
  grid-template-columns: 0px min-content;
  margin: 2px;
  padding-bottom: 2px;
  padding-top: 2px;
  color: rgb(51, 51, 51);
  box-sizing: border-box;
  padding-left: 8px !important;
} 

.css-qr46ko {
  max-height: 300px;
  overflow-y: auto;
  position: relative;
  padding-bottom: 4px;
  padding-top: 4px;
  box-sizing: border-box;
  padding: 8px !important;
}

@media (max-width: 850px) {
  .popup-modal .popup-modal-body.xsm {
    width: 80%;
  }

  .popup-modal .popup-modal-body.sm {
    width: 80%;
  }

  .popup-modal .popup-modal-body.md {
    width: 80%;
  }

  .popup-modal .popup-modal-body.lg {
    width: 80%;
  }

  .popup-modal .popup-modal-body.xlg {
    width: 80%;
  }

  .popup-modal h2 {
    font-size: 16px;
  }

  .popup-modal h3 {
    font-size: 20px;
  }
}

@media (max-width: 600px) {
  .popup-modal .popup-modal-body.xsm {
    width: 95%;
  }

  .popup-modal .popup-modal-body.sm {
    width: 95%;
  }

  .popup-modal .popup-modal-body.md {
    width: 95%;
  }

  .popup-modal .popup-modal-body.lg {
    width: 95%;
  }

  .popup-modal .popup-modal-body.xlg {
    width: 95%;
  }

  .popup-modal h2 {
    font-size: 16px;
  }

  .popup-modal h3 {
    font-size: 20px;
  }

  .left-popup {
    width: 100%;
  }
}