/* Tuto login home */
.tutor-login-home {
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    overflow-y: auto;
}

.lg-bg-image {
    background-image: url('../images/banner.png');
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.box-height {
    height: 49px !important;
}

.tutor-login-home .login-home-box {
    border-radius: 10px;
    padding: 10px;
}

.btn-tutor-profile {
    border-radius: 13px;
    background-color: #58cc24;
    box-shadow: 0 4px 2px #58A71C !important;
    color: white;
    font-family: "Fredoka One", cursive;
    text-transform: uppercase;
    padding: 6px 10px !important;
    font-size: 14px !important;
    height: 49px;
    letter-spacing: 0.84px;
}

.btn-face {
    border-radius: 10px;
    border: 2px solid rgb(235, 234, 234);
    padding: 5px 10px !important;
    font-weight: bold;
    color: rgb(41, 41, 160);
    font-family: "Roboto", sans-serif;
    background-color: white;
    text-transform: uppercase;
}

.btn-google {
    color: rgb(90, 177, 211);
    border-radius: 10px;
    border: 2px solid rgb(235, 234, 234);
    padding: 5px 10px !important;
    font-weight: bold;
    font-family: "Roboto", sans-serif;
    text-transform: uppercase;
    background-color: white;
}

.btn-login {
    border-radius: 13px;
    background-color: #20bef8;
    box-shadow: 0 4px 2px #28aedf !important;
    color: white;
    font-family: "Fredoka One", cursive;
    text-transform: uppercase;
    padding: 6px 10px !important;
    font-size: 14px;
    height: 49px;
    letter-spacing: 0.84px;
}

.inline-text p {
    width: 80%;
    text-align: center;
    border-bottom: 1px solid rgb(211, 207, 207);
    line-height: 0.1em;
    margin: 10px 0 20px;
    color: #C3C3C3;
}

.inline-text p span {
    background: #fff;
    padding: 0 10px;
}

/* create tutor profile  */
.tutor-login-home .input-box input {

    /* width: 335px; */
    width: 100%;
    max-width: 100%;
    height: 49px;
    background: #F9F9F9 0% 0% no-repeat padding-box;
    border: 1px solid #EAEAEA;
    border-radius: 15px;
    outline: none;
    display: block;
    margin: 0 0 10px 0 !important;
    padding: 10px !important;
    font: normal normal normal 14px Fredoka One;
}

/* select role  */
.select-role .role-box .role-body {
    height: 160px;
    width: 160px;
    border-radius: 10px;
    border: 2px solid #dee1e2;

}

.select-role .role-box .active-box {
    border: 2px solid #20bef8;
}

.bottom-clip {
    background-image: url('../images/grass-dark.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    width: 100%;
    height: 50px;
    border-radius: 100px 30px 100px 0;
}

/* welcome  */
.tutor-login-home .bottom-parrot {
    bottom: -90px;
    right: 90px;
    position: fixed;
}

.tutor-login-home .bottom-parrot img {
    width: 200px;
}

.overlay-bg {
    height: 75%;
    width: 50%;
    border-radius: 0 600px 600px 600px;
    background: #FFCB00 0% 0% no-repeat padding-box;
    mix-blend-mode: multiply;
    opacity: 1;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tutor-login-home .overlay-text {
    position: fixed;
    top: 40px;
    left: 40px;
    z-index: 2;
}

.login-footer-text {
    text-align: center;
    font-size: 12px;
    letter-spacing: 0px;
    color: #A2A2A2;
}


/* otp  */
.otp .input-box input {
    max-width: 40px !important;
    border: none;
    padding: 5px !important;
    background-color: #e8f0f0;
    outline: none;
    border-radius: 5px;
    margin: 10px !important;
}

/* little more about yourself */
.little-more {
    width: 300px;
}

/* parrot animation */
.p-anim {
    animation: parrot 1s infinite;

}

@keyframes parrot {
    0% {
        transform: scale(0.4)
    }

    100% {
        transform: scale(0.8)
    }
}

@media (max-width:850px) {
    .overlay-bg {
        height: 70%;
        width: 50%;
        background-color: rgba(243, 186, 0, 0.8);
        border-radius: 0 450px 450px 450px;
    }
}

@media (max-width:600px) {

    .tutor-login-home {
        background-image: none !important;
    }

    .tutor-login-home .bottom-parrot {
        bottom: -110px;
        right: 0px;
    }

    .tutor-login-home .bottom-parrot img {
        width: 150px;
    }

    .heading-y-margin {
        margin: 30px 0 !important;
    }
}