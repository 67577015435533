.web-user-testimonial-box {
    background: #F8F8F8 0% 0% no-repeat padding-box;
    border-radius: 24px;
    margin: 20px 0px !important;
    padding: 20px !important;
    transition: 0.4s;
}

.web-user-testimonial-q {
    font: normal normal normal 24px/32px MuseoSansRounded700;
    color: var(--color-light-grey);
    margin: 20px 0 !important;
}

.web-user-testimonial-ans {
    font: normal normal normal 16px/24px MuseoSansRounded500;
    color: var(--color-light-grey);
    /* opacity: 0.7; */
    height: 0;
    overflow: hidden;
    transition: 0.4s;
}

.web-user-testimonial-ans-active {
    height: initial;
    overflow: initial;
    transition: 0.4s;
}

.web-user-testimonial-arrow-up-box {
    width: 32px;
    height: 32px;
    background: #199B21 0% 0% no-repeat padding-box;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.web-user-testimonial-arrow-down-box {
    width: 32px;
    height: 32px;
    background: #1B221B 0% 0% no-repeat padding-box;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

@media (max-width :650px) {
    .web-testimonial {
        padding: 0 20px !important;
    }

    .web-user-testimonial-q {
        font: normal normal normal 18px/28px MuseoSansRounded700;
    }

    .web-user-testimonial-ans {
        font: normal normal normal 14px/20px MuseoSansRounded500;
    }
}