.message-popup {
    position: fixed;
    top: 20px;
    z-index: 5;
    left: 0%;
    right: 0%;
}

.message-popup .message-pop-body {
    min-width: 200px;
    max-width: 400px;
    border-radius: 20px;
    text-align: center;
}

.message-popup .message-pop-body.error {
    background-color: #fc595c62;
    color: #FF5757;
}

.message-popup .message-pop-body.success {
    background-color: #089d1250;
    color: #089D12;
}