.tutor-check {
    background-color: #F4F6FB;
    height: 100vh;
}

.tutor-check .left-dash li {
    margin: 10px 0px !important;
    padding: 5px 5px !important;
    border: 2px solid rgb(206, 203, 203);
    border-radius: 10px;
    font-size: 14px;
    list-style: none;
}

.tutor-check .left-dash .logout:hover {
    border: 2px solid #20bef8;
    background-color: white;
}

.tutor-check .right-dash {
    height: 99vh;
    overflow: auto;
}

.alert {
    background-color: #FFCA0026;
    border-radius: 10px;
}

/* baisc info */
.tutor-check .basic-info ul li {
    margin: 10px 0px !important;
    padding: 5px 5px !important;
    border: 2px solid rgb(236, 234, 234);
    list-style: none;
    border-radius: 10px;
}

.edit-info {
    border-radius: 5px;
    background-color: #dae6d4;
    color: #41b30c;
    font-family: "MuseoSansRounded700", regular;
    font-size: 12px;
    padding: 2px 5px !important;
}

.tutor-check .ask-social {
    margin: 10px 10px !important;
    padding: 10px 10px !important;
    border: 2px solid rgb(236, 234, 234);
    border-radius: 10px;
}

.tutor-check .active-tab {
    border: 2px solid white !important;
    background-color: white !important;
}

.tutor-check .active-border {
    border: 2px solid #20bef8 !important;

}

.lang-tags {
    border-radius: 5px;
    /* border:2px solid rgb(230, 224, 224); */
    padding: 2px 4px !important;
    background-color: whitesmoke;
    color: green;

}

/* input field  */
.tutor-check .input-box,
.tutor-dash .input-box {
    border: 1px solid #d4d4d4;
    outline: none;
    height: 49px;
    border-radius: 10px;
    background-color: whitesmoke;
}

.tutor-check .input-box:focus,
.tutor-dash .input-box:focus {
    border: 2px solid #066d13;
    background-color: white;
}

/* Phone numver select */
.phone-number-box {
    width: 100% !important;
    box-shadow: none !important;
    outline: none;
    border-radius: 10px;
}


.react-tel-input .form-control {
    position: relative;
    font-size: 14px;
    letter-spacing: .01rem;
    margin-top: 0 !important;
    margin-bottom: 0px !important;
    padding-left: 48px !important;
    margin-left: 0;
    line-height: 25px;
    height: 49px !important;
    width: 100%;
    outline: none;
    border-radius: 10px !important;
    background-color: whitesmoke !important;
}

.react-tel-input .flag-dropdown {
    border-radius: 10px 0px 0px 10px !important;
    background-color: #f5f5f5;
    border: 1px solid #d4d4d4;
}

.react-tel-input .selected-flag {
    width: 38px;
    height: 49px;
    border-radius: 10px 0px 0px 10px !important;
    background-color: whitesmoke !important;
}

.react-tel-input .flag {
    margin: 0px 5px !important;
}

.react-tel-input .form-control:focus {
    border: 2px solid #066d13;
    background-color: white !important;
}

.phone-number {
    width: 100% !important;
    box-shadow: none !important;
    height: 49px;
    outline: none;
    height: 49px;
    border-radius: 10px;
    background-color: inherit;
    font-family: "Fredoka One", regular;
}


@media (max-width:600px) {
    .tutor-check .right-dash {
        height: 100%
    }

}