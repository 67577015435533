.aboutus .about-text {
    font: normal normal normal 24px/34px MuseoSansRounded300;
    letter-spacing: 0px;
    color: var(--color-light-grey);
    opacity: 0.7;
}

.aboutus .about-text-2 {
    font: normal normal normal 24px/34px MuseoSansRounded500;
    letter-spacing: 0px;
    color: var(--color-light-grey);
}

.about-heading {
    font: normal normal normal 50px/58px Fredoka One;
    letter-spacing: 0px;
    color: var(--color-light-grey);
}

.about-body {
    margin-top: 150px !important;
}
.aboutus ul li{
    margin-left: 20px !important;
}

@media (max-width :650px) {
    .aboutus-pad {
        padding: 0 20px !important;
    }

    .about-heading {
        font: normal normal normal 30px/38px Fredoka One !important;
    }

    .about-body {
        margin-top: 100px !important;
    }
}