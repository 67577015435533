.contactus-body {
    margin-top: 150px !important;
}

.contact-img {
    height: 750px;
    background: url('../images/home/map.png');
}

.contactus-form {
    margin-right: 60px !important;
    padding: 30px 60px !important;
    float: right;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 20px 40px #00000014;
    border-radius: 32px;
}

.contactus-form-heading {
    font: normal normal normal 45px/30px Fredoka One;
    letter-spacing: 0px;
    color: #089D12;
}

.contactus-form input,
textarea,
select {
    width: 335px;
    max-width: 100%;
    height: 49px;
    background: #F9F9F9 0% 0% no-repeat padding-box;
    border: 1px solid #EAEAEA;
    border-radius: 15px;
    outline: none;
    display: block;
    margin: 10px 0 !important;
    padding: 10px !important;
    font: normal normal normal 14px Fredoka One;
}

.contect-form-submit-disable {
    background: #EAEAEA 0% 0% no-repeat padding-box;
    border-radius: 15px;
    text-align: center;
    font: normal normal normal 14px/17px Fredoka One;
    letter-spacing: 0.84px;
    color: #BDBDBD;
    text-transform: uppercase;
    width: 100%;
    height: 49px;
    box-shadow: none !important;
}
.contect-form-submit-disable:active {
    transform: scale(1) !important;
}

.contect-form-submit-enable {
    background: var(--color-sky) 0% 0% no-repeat padding-box;
    border-radius: 15px;
    text-align: center;
    font: normal normal normal 14px/17px Fredoka One;
    letter-spacing: 0.84px;
    color: white;
    text-transform: uppercase;
    width: 100%;
    height: 49px;
    box-shadow: none !important;
}
.contactus .address{
   font-size: 18px;
   font-family: 'MuseoSansRounded700';
   margin-bottom: 20px !important;
}
.contactus .address p{
   max-width: 100% !important;
   font-size: 18px;
 }
 .contactus .address p span{
  font-size: 16px;
  font-family: 'MuseoSansRounded500';
  }

@media (max-width :650px) {
    .contact-img {
        padding: 0 10px !important;
    }

    .contactus-form {
        width: 100% !important;
        margin-right: 20px !important;
        padding: 20px 20px !important;
        float: none !important;
    }

    .contactus-form-heading {
        font: normal normal normal 30px/20px Fredoka One !important;
    }

    .contactus-body {
        margin-top: 100px !important;
    }
}